import { createStore } from 'vuex'
import AppConfig from '@/App.Config.js'
var gConfig = AppConfig()

export default createStore({	
	state: {
		showGnb: true,
		showAlert: {show: false, msg:'', btn:'', callback: null},
		showToast: {show: false, msg:''},
		showLoading: false,
		showGameStartLoading: false,
		showLoginPopup: false,
		showSelectWalletPopup: {},
		showEventPopup: false,
		showAddWallet: false,
		showChangePasswordPopup: false,
		showEditProfile: false,
		showReport: false,
		showStakingModal:{},
		showSuccessModal:{},
		showConfirmModal:{},
		showInfoModal: {},
		showRewardTable: {},
		showMyReward: {},
		show4thLandSale: {},
		showMaintenance: {},
		show4thReward: {},
		showTransactionError: {},
		showEventNoti: {},
		showEventBuy: {},
		showMainNoti: {},
		dataClickedInfoModal: null,
		countryCodeNo: '82',
		userInfo: {
			"id": "",
			"country": "",
			"account": "",
			"phone_number": "",
			"email": "",
			"name": "",
			"gender": "",
			"birth": "",
			"admin_category": "",
			"intro": "false",
			"affiliation": "",
			"department": "",
			"position": "",
			"nickname": ""
		},
		// // accounts = [{ account:userInfo.account, createtime: "2021-06-29 20:07:15". wallet_addr:"0x...." }, ...]
		wallet: {
			updated: false,
			currentAccountIdx: null,
			currentAccount:null,
			accounts:[],
			provider: null,
			signer: null,
			balance: 0,
			polygonBalance: 0,
			balanceWallet: 0,
		},
		network: '',
		homeNews: {}, // { total, page, cpp, list[]} // cpp = number of items (count) per page
		news: {},
		newsItem:{},
		marketItems: {}, // { total, page, cpp, list[]} // cpp = number of items (count) per page
		marketItem: {},
		marketQuery: {},
		myLands: {},
		myLandQuery: {},
		myItems: {}, // { total, page, cpp, list[]} // cpp = number of items (count) per page
		myItemQuery: {},
		landItems: {},
		landItemsInPopup: {},
		defaultLandItemsPopup: {},
		landMenuPopup: {},
		defaultLandMenu: {},
		categorySelected: undefined,
		listProposals: [],
		defaultCountryCode: '82',

		// config value - jhk
		config: {},

		// json data - jhk
		itemData: {},
		languageData: {}
	},
	mutations: {
		SHOW_GNB(state, value) {
			state.showGnb = value;
		},
		SHOW_ALERT(state, value) {
			state.showAlert = value;
		},
		SHOW_TOAST(state, value) {
			state.showToast = value;
		},
		SHOW_LOADING(state, value) {
			state.showLoading = value;
		},
		SHOW_GAME_START_LOADING(state, value) {
			state.showGameStartLoading = value;
		},
		SHOW_LOGIN_POPUP(state, value) {
			state.showLoginPopup = value;
		},
		SHOW_SELECT_WALLET_POPUP(state, value) {
			state.showSelectWalletPopup = value;
		},
		SHOW_EVENT_POPUP(state, value) {
			state.showEventPopup = value;
		},
		SHOW_ADD_WALLET(state, value) {
			state.showAddWallet = value;
		},
		SHOW_CHANGE_PASSWORD_POPUP(state, value) {
			state.showChangePasswordPopup = value;
		},
		SHOW_EDIT_PROFILE(state, value) {
			state.showEditProfile = value;
		},
		SHOW_REPORT(state, value) {
			state.showReport = value
		},
		SHOW_STAKING_MODAL(state, value) {
			state.showStakingModal = value;
		},
		SHOW_SUCCESS_MODAL(state, value) {
			state.showSuccessModal = value;
		},
		SHOW_CONFIRM_MODAL(state, value) {
			state.showConfirmModal = value;
		},
		SHOW_INFO_MODAL(state, value) {
			state.showInfoModal = value;
		},
		SHOW_REWARD_TABLE(state, value) {
			state.showRewardTable = value;
		},
		CLICK_INFO_MODAL(state, value) {
			state.dataClickedInfoModal = value;
		},
		SET_USER_INFO(state, value) {
			state.userInfo = value;
		},
		SHOW_MY_REWARD(state, value) {
			state.showMyReward = value;
		},
		SHOW_4TH_LANDSALE(state, value) {
			state.show4thLandSale = value;
		},
		SHOW_MAINTENANCE(state, value) {
			state.showMaintenance = value;
		},
		SHOW_4TH_REWARD(state, value) {
			state.show4thReward = value;
		},
		SHOW_TRANSACTION_ERROR(state, value) {
			state.showTransactionError = value;
		},
		SHOW_EVENT_NOTI(state, value) {
			state.showEventNoti = value;
		},
		SHOW_EVENT_BUY(state, value) {
			state.showEventBuy = value;
		},
		SHOW_MAIN_NOTI(state, value) {
			state.showMainNoti = value;
		},
		SHOW_IMAGE(state, value) {
			state.showImage = value;
		},
		SET_COUNTRY_CODE_NO(state, value) {
			state.countryCodeNo = value;
		},

		SET_WALLET(state, value) {
			state.wallet = value;
			state.wallet.updated= true;
		},
		SET_WALLET_BALANCE(state,value) {
			state.wallet.updated = false;
			state.wallet.balance = value;
		},
		SET_WALLET_POLYGON_BALANCE(state,value) {
			state.wallet.updated = false;
			state.wallet.polygonBalance = value;
		},
		SET_BALANCE(state, value) {
			state.wallet.balanceWallet = value
		},
		SET_NETWORK(state,value) {
			state.network = value;
		},
		SET_ENJIN_WALLET(state, value) {
			state.userInfo.enjinWalletAddr = value;
		},

		/////// Home ////
		SET_HOME_NEWS(state, value) {
			state.homeNews = value;
		},
		////// Market ////
		SET_MARKET_ITEMS(state, value) {
			state.marketItems = value;
		},
		SET_MARKET_ITEM(state, value) {
			state.marketItem = value;
		},
		SET_MARKET_QUERY(state, value) {
			state.marketQuery = value;
		},
		SET_LAND_QUERY(state, value) {
			state.landQuery = value;
		},
		SET_LAND_INFO(state, value) {
			state.landInfo = value;
		},
		SET_LAND_MENU(state, value) {
			state.landMenu = value;
		},
		SET_LAND_MENU_POP_UP(state, value) {
			state.landMenuPopup = value;
		},
		SET_DEFAULT_LAND_MENU(state, value) {
			state.defaultLandMenu = value;
		},
		////// LAND /////////
		SET_LAND_ITEMS(state, value) {
			state.landItems = value;
		},
		SET_LAND_ITEMS_POPUP_STAKING(state, value) {
			state.landItemsInPopup = value;
		},
		SET_LAND_ITEMS_DEFAULT_POPUP_STAKING(state, value) {
			state.defaultLandItemsPopup = value;
		},
		SET_LAND_ITEM(state, value) {
			state.landtItem = value;
		},
		SET_LAND_ITEM_DETAIL(state, value) {
			state.landItemDetail = value;
		},


		////// My Lands ////
		SET_MY_LANDS(state, value) {
			state.myLands = value;
		},
		SET_MY_LAND_QUERY(state, value) {
			state.myLandQuery = value;
		},

		////// My Items ////
		SET_MY_ITEMS(state, value) {
			state.myItems = value;
		},
		SET_My_ITEM(state, value) {
			state.myItem = value;
		},
		SET_MY_ITEM_QUERY(state, value) {
			state.myItemQuery = value;
		},

		////// News //////
		SET_NEWS(state, value) {
			state.news = value;
		},
		SET_NEWS_ITEM(state, value) {
			state.newsItem = value;
		},

		SET_NEW_CATEGORY(state, value) {
			state.categorySelected = value
		},
		SET_LIST_PROPOSALS(state, value) {
			state.listProposals = value
		}
	},
	actions: {
		showGnb(context, value) {
			context.commit('SHOW_GNB',value);
		},
		showAlert(context, value) {
			// console.log("[STORE.actions] showAlert(), ", value);
			context.commit('SHOW_ALERT',value);
		},
		showToast(context, value) {
			// console.log("[STORE.actions] showToast(), ", value);
			context.commit('SHOW_TOAST',value);
		},
		showLoading(context, value) {
			// console.log("[STORE.actions] showLoading(), ", value);
			context.commit('SHOW_LOADING',value);
		},
		showGameStartLoading(context, value) {
			// console.log("[STORE.actions] showGameStartLoading(), ", value);
			context.commit('SHOW_GAME_START_LOADING',value);
		},
		showLoginPopup(context, value) {
			// console.log("[STORE.actions] showLoginPopup(), ", value);
			context.commit('SHOW_LOGIN_POPUP',value);
		},
		showSelectWalletPopup(context, value) {
			// console.log("[STORE.actions] showSelectWalletPopup(), ", value);
			context.commit('SHOW_SELECT_WALLET_POPUP', value)
		},
		showEventPopup(context, value) {
			// console.log("[STORE.actions] showEventPopup(), ", value);
			context.commit('SHOW_EVENT_POPUP',value);
		},

		showAddWallet(context, value) {
			// console.log("[STORE.actions] showAddWallet(), ", value);
			context.commit('SHOW_ADD_WALLET',value);
		},

		showChangePasswordPopup(context, value) {
			// console.log("[STORE.actions] showChangePasswordPopup(), ", value);
			context.commit('SHOW_CHANGE_PASSWORD_POPUP',value);
		},

		showEditProfile(context, value) {
			// console.log("[STORE.actions] showEditProfile(), ", value);
			context.commit('SHOW_EDIT_PROFILE',value);
		},
		showReport(context, value) {
			context.commit('SHOW_REPORT', value)
		},
		showStakingModal(context, value) {
			// console.log("[STORE.actions] showStakingModal(), ", value);
			context.commit('SHOW_STAKING_MODAL',value);
		},
		showSuccessModal(context, value) {
			// console.log("[STORE.actions] showSuccessModal(), ", value);
			context.commit('SHOW_SUCCESS_MODAL',value);
		},
		showConfirmModal(context, value) {
			// console.log("[STORE.actions] showConfirmModal(), ", value);
			context.commit('SHOW_CONFIRM_MODAL',value);
		},
		showInforModal(context, value) {
			context.commit('SHOW_INFO_MODAL', value);
		},
		showRewardTable(context, value) {
			context.commit('SHOW_REWARD_TABLE', value);
		},
		clickInfoModal(context, value) {
			context.commit('CLICK_INFO_MODAL', value);
		},
		setUserInfo(context, value) {
			// console.log("[STORE.actions] setUserInfo(), ", value);
			context.commit('SET_USER_INFO',value);
		},
		showMyRewardModal(context, value) {
			context.commit('SHOW_MY_REWARD', value)
		},
		show4thLandSaleModal(context, value) {
			context.commit('SHOW_4TH_LANDSALE', value)
		},
		showMaintenanceModal(context, value) {
			context.commit('SHOW_MAINTENANCE', value);
		},
		show4thRewardModal(context, value) {
			context.commit('SHOW_4TH_REWARD', value)
		},
		showTransactionErrorModal(context, value) {
			context.commit('SHOW_TRANSACTION_ERROR', value)
		},
		showEventNotiModal(context, value) {
			context.commit('SHOW_EVENT_NOTI', value)
		},
		showEventBuyModal(context, value) {
			context.commit('SHOW_EVENT_BUY', value);
		},
		showMainNotiModal(context, value) {
			context.commit('SHOW_MAIN_NOTI', value)
		},
		showImageModal(context, value) {
			context.commit('SHOW_IMAGE', value)
		},
		setCountryCodeNo(context, value) {
			// console.log("[STORE.actions] setCountryCodeNo(), ", value);
			context.commit('SET_COUNTRY_CODE_NO',value);
		},
		setWallet(context, value) {
			// console.log("[STORE.actions] setWallet(), ", value);
			context.commit('SET_WALLET',value);
		},
		setWalletBalance(context, value) {
			// console.log("[STORE.actions] setWalletBalance(), ", value);
			context.commit('SET_WALLET_BALANCE',value);
		},
		setWalletPolygonBalance(context, value) {
			// console.log("[STORE.actions] setWalletBalance(), ", value);
			context.commit('SET_WALLET_POLYGON_BALANCE',value);
		},
		setBalance(context, value) {
			context.commit('SET_BALANCE', value)
		},
		setNetwork(context, value) {
			context.commit('SET_NETWORK', value)
		},
		setEnjinWallet(context, value) {
			context.commit('SET_ENJIN_WALLET', value)
		},
		/// Home ////
		setHomeNews(context, value) {
			// console.log("[STORE.actions] setHomeNews(), ", value);
			context.commit('SET_HOME_NEWS',value);
		},
		/// Market ////
		setMarketItems(context, value) {
			// console.log("[STORE.actions] setMarketItems(), ", value);
			context.commit('SET_MARKET_ITEMS',value);
		},
		setMarketItem(context, value) {
			// console.log("[STORE.actions] setMarketItem(), ", value);
			context.commit('SET_MARKET_ITEM',value);
		},

		setMarketQuery(context, value) {
			// console.log("[STORE.actions] setMarketQuery(), ", value);
			context.commit('SET_MARKET_QUERY',value);
		},

		/// LAND ////
		setLandQuery(context, value) {
			// console.log("[STORE.actions] setLandQuery(), ", value);
			context.commit('SET_LAND_QUERY',value);
		},
		setLandInfo(context, value) {
			// console.log("[STORE.actions] setLandInfo(), ", value);
			context.commit('SET_LAND_INFO',value);
		},

		setLandMenu(context, value) {
			// console.log("[STORE.actions] setLandMenu(), ", value);
			context.commit('SET_LAND_MENU',value);
		},
		setLandMenuPopUp(context, value) {
			// console.log("[STORE.actions] setLandMenu(), ", value);
			context.commit('SET_LAND_MENU_POP_UP',value);
		},
		setDefaultLandMenu(context, value) {
			// console.log("[STORE.actions] setLandMenu(), ", value);
			context.commit('SET_DEFAULT_LAND_MENU',value);
		},
		setLandItems(context, value) {
			// console.log("[STORE.actions] setLandItems(), ", value);
			context.commit('SET_LAND_ITEMS',value);
		},
		setLandItemsInPopupStaking(context, value) {
			context.commit('SET_LAND_ITEMS_POPUP_STAKING',value);
		},
		setLandItemsDefaultInPopupStaking(context, value) {
			context.commit('SET_LAND_ITEMS_DEFAULT_POPUP_STAKING',value);
		},
		setLandItem(context, value) {
			// console.log("[STORE.actions] setLandItem(), ", value);
			context.commit('SET_LAND_ITEM',value);
		},
		setLandItemDetail(context, value) {
			// console.log("[STORE.actions] setLandItem(), ", value);
			context.commit('SET_LAND_ITEM_DETAIL',value);
		},

		/// My Land ////
		setMyLands(context, value) {
			// console.log("[STORE.actions] setMyItems(), ", value);
			context.commit('SET_MY_LANDS',value);
		},

		setMyLandQuery(context, value) {
			// console.log("[STORE.actions] setMyItemQuery(), ", value);
			context.commit('SET_MY_LAND_QUERY',value);
		},

		/// My Item ////
		setMyItems(context, value) {
			// console.log("[STORE.actions] setMyItems(), ", value);
			context.commit('SET_MY_ITEMS',value);
		},

		setMyItemQuery(context, value) {
			// console.log("[STORE.actions] setMyItemQuery(), ", value);
			context.commit('SET_MY_ITEM_QUERY',value);
		},

		/// News ////
		setNews(context, value) {
			// console.log("[STORE.actions] setNews(), ", value);
			context.commit('SET_NEWS',value);
		},
		setNewsItem(context, value) {
			// console.log("[STORE.actions] setNewsItem(), ", value);
			context.commit('SET_NEWS_ITEM',value);
		},
		setNewCategory(context, value) {
			context.commit('SET_NEW_CATEGORY', value)
		},
		setListProposals(context, value) {
			context.commit('SET_LIST_PROPOSALS', value)
		}
	},
	modules: {},
	getters: {
		getAddr(state) {
			return gConfig.isProd? state.config.addrProd : state.config.addrDev
		},
		getEthAddr(state) {
			return gConfig.isProd? state.config.addrProd : state.config.addrDev
		},
		getBscAddr(state) {
			return gConfig.isProd? state.config.addrBscProd : state.config.addrBscDev
		},
		getPolygonAddr(state) {
			return gConfig.isProd? state.config.addrPolygonProd :state.config.addrPolygonDev
		},
		get3rdLandBscAddr(state) {
			return gConfig.isProd? state.config.addr3rdLandBscProd : state.config.addr3rdLandBscDev
		},
		get3rdLandPolAddr(state) {
			return gConfig.isProd? state.config.addr3rdLandPolProd : state.config.addr3rdLandPolDev
		},
		getMarketEthAddr(state) {
			return gConfig.isProd? state.config.addrMarketEthProd : state.config.addrMarketEthDev
		},
		getMarketBscAddr(state) {
			return gConfig.isProd? state.config.addrMarketBscProd : state.config.addrMarketBscDev
		},
		getMarketPolAddr(state) {
			return gConfig.isProd? state.config.addrMarketPolProd : state.config.addrMarketPolDev
		},
		getBidAddr(state) {
			return state.config.addrBid
		},
		getProxyBscAddr(state) {
			return gConfig.isProd? state.config.addrProxyBscProd : state.config.addrProxyBscDev
		},
		getProxyPolygonAddr(state) {
			return gConfig.isProd? state.config.addrProxyPolygonProd: state.config.addrProxyPolygonDev
		},
		getStakingEthAddr(state) {
			return gConfig.isProd? state.config.addrProd.stakingAddress : state.config.addrDev.stakingAddress
		},
		getStakingBscAddr(state) {
			return gConfig.isProd? state.config.addrBscProd.stakingAddress : state.config.addrBscDev.stakingAddress
		},
		getStakingPolAddr(state) {
			return gConfig.isProd? state.config.addrPolygonProd.stakingAddress : state.config.addrPolygonDev.stakingAddress
		},
		getNetworkAddr: (state, getters) => (network, market_index) => {			
			if (market_index != undefined && market_index != '-1') {
				let market
				let getAddrs
				if (network == 'ETH') {
					market = getters.getMarketEthAddr
				} else if (network == 'BSC') {
					market = getters.getMarketBscAddr
				} else if (network == 'POL') {
					market = getters.getMarketPolAddr
				} else {
					return ''
				}
				getAddrs = {
					ContractMarketAddress: market.ContractMarketAddress,
					...market.addrs[market_index],
				}
				return getAddrs
			} else {
				if (network == 'ETH') {
					return getters.getAddr
				} else if (network == 'BSC') {
					return getters.getBscAddr
				} else if (network == 'POL') {
					return getters.getPolygonAddr
				} else if (network == 'BSC3') {
					return getters.get3rdLandBscAddr
				} else if (network == 'POL3') {
					return getters.get3rdLandPolAddr
				} else {
					return ''
				}
			}
		},
		getMysteryboxAddr: (state) => (network) => {
			if (gConfig.isProd) {
				if(network == 'BSC') {
					return state.config.addrMarketBscProd.addrs[1].Contract1155Address;
				} else if(network == 'POL') {
					return state.config.addrMarketPolProd.addrs[0].Contract1155Address;
				}
			}
			if(network == 'BSC') {
				return state.config.addrMarketBscDev.addrs[1].Contract1155Address;
			} else if(network == 'POL') {
				return state.config.addrMarketPolDev.addrs[0].Contract1155Address;
			}
		},
		get4thLandBscAddr: (state) => (type) => {
			if (gConfig.isProd) {
				switch(type) {
					case "token":
						return state.config.addrMarketBscProd.addrs[2].TokenAddress;
					case "721":
						return state.config.addrMarketBscProd.addrs[2].Contract721Address;
					case "mysterybox":
						return state.config.addrMarketBscProd.addrs[2].ContractMysteryBoxAddress;
				}
			}
			switch(type) {
				case "token":
					return state.config.addrMarketBscDev.addrs[2].TokenAddress;
				case "721":
					return state.config.addrMarketBscDev.addrs[2].Contract721Address;
				case "mysterybox":
					return state.config.addrMarketBscDev.addrs[2].ContractMysteryBoxAddress;
			}
		},
		/**
		 * 온오프체인에 필요한 컨트랙트 리스트
		 * @param {string} chain eth. bsc. pol
		 * @returns 컨트랙트 리스트
		 */
		getRequireOnOffChainApprove: (state) => (chain) => {
			const t = state.config.addrRequireOnOffChainApprove[chain];
			return t;
		},
		/**
		 * 구매 이벤트 정보
		 * @param {string} chain eth. bsc. pol
		 * @param {string} category1 대분류
		 * @param {string} category2 소분류
		 */
		getPurchaseInfo: (state) => (chain, category1, category2) => {
			let t = state.config.purchaseInfo[chain.toLowerCase()];
			if(category1 != undefined)
				t = t[category1];

			if(category2 != undefined)
				t = t[category2];

			return t;
		}
	}
})
